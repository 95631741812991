<template>
  <div    >
    <v-dialog
    :value="passDialog"
    width="unset"
    content-class="detail-report-container"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card outlined color="transparent">
      <v-toolbar class="detail-toolbar">
        <v-toolbar-title class="detail-report-title py-0" >{{
          $t("title.invoice")
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          icon
          color="teal"
          style="font-size: 10px; margin-top:0px;"
          @click="$emit('closeDetailDialog')"
        >
          <v-icon color="warning">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
            <template>
              <v-data-table
                :headers="headerDetailReport"
                mobile-breakpoint="nan"
                hide-default-header
                :hide-default-footer="true"
                item-key="_id"
                :items="passGetInvoiceData ? passGetInvoiceData : []"
                :server-items-length="
                  passGetInvoiceData ? passGetInvoiceData.total_amount : 0
                "
                :loading="passDetailLoading"
                :loading-text="$t('datatable.loadingData')"
                class="elevation-0 detail-data-table pa-0"
                style="height:130px; background-color: #f0f4f7;"
              >
                <template v-slot:header="{ props }">
                  <th
                    v-for="(header, index) in props.headers"
                    :key="index"
                    class="pt-2 pl-4 header-text-style text-center"
                    :style="header.width"
                  >
                    {{ $t(`title.${header.text}`) }}
                  </th>
                </template>

                <template v-slot:[`item.date`]="{ item }">
                  <h6 class="text-center">{{ item.time }}</h6>
                </template>

                <template v-slot:[`item.no`]="{ item }">
                  <h6 class="text-center">
                    {{ moment(item.time).format("YYYYMMDD") + "#" + item.no }}
                  </h6>
                </template>

                <template v-slot:[`item.bet_type.name`]="{ item }">
                  <h6 class="betting-type-style-report" >
                    <BettingTypeSection
                      :item="item"
                      :passVariableModuleName="passVariableModuleName"
                      :channel_type="passGetInvoiceData.channel_type"
                    >
                    </BettingTypeSection>
                  </h6>
                </template>

                <template v-slot:[`item.bet_result`]="{ item }">
                  <h6  class="betting-type-style-report">
                    <Results
                      :item="item"
                      :passVariableModuleName="passVariableModuleName"
                      :channel_type="passGetInvoiceData.channel_type"
                    ></Results>
                  </h6>
                </template>

                <template v-slot:[`item.total_amount`]="{ item }">
                  <h6 class="pt-2  text-center">{{ formatMoney(item.total_amount) }}</h6>
                </template>

                <template v-slot:[`item.total_amount_win`]="{ item }">
                  <h6
                    v-if="item.total_amount_win || item.total_amount_win == 0 "
                    class="pt-2 text-center"
                    :class="
                      item.total_amount_win < 0
                        ? 'text-danger'
                        : item.total_amount_win > 0
                        ? 'text-primary'
                        : ''
                    "
                  >
                    {{ formatMoney(item.total_amount_win) }}
                  </h6>
                  <h6 class="text-center" v-else>{{ $t("title.game_processing") }}</h6>
                </template>

                <template v-slot:[`item.total_cast`]="{ item }">
                  <h6 v-if="item.total_cast || item.total_cast == 0 " class="text-center">
                    {{ formatMoney(item.total_cast) }}
                  </h6>
                  <h6 v-else class="text-center">{{ $t("title.game_processing") }}</h6>
                </template>
              </v-data-table>
            </template>
      <v-card-text
        v-if="
          passVariableModuleName == 'm_yuki' ||
          passVariableModuleName == 'm_lottery' ||
          passVariableModuleName == 'm_lottery3'
        "
        class="px-0 mt-2 mb-0 py-0"
      >
        <v-row class="mx-0 pa-0">
          <v-col
            cols="12"
            class="pa-0"
          >
            <v-toolbar-title class="invoice-title pl-4 py-1">{{
              $t("title.showDetailInvoice")
            }}</v-toolbar-title>
          </v-col>
          <!-- new -->
          <v-col  class="pa-0" v-show="passIsShow">
            <template>
              <v-data-table
                :headers="headerDetailReport"
                mobile-breakpoint="NaN"
                hide-default-header
                hide-default-footer
                :items="
                  passGetInvoiceDetailData ? passGetInvoiceDetailData : []
                "
                :loading="passDetailLoading"
                :sort-desc.sync="dataDetail.descendingFormat"
                :sort-by.sync="dataDetail.sortByFormat"
                :loading-text="$t('datatable.loadingData')"
                class="elevation-1 detail-data-table detail-invoice"
              >
                <template v-slot:header="{ props }">
                  <th
                    v-for="(header, index) in props.headers"
                    :key="index"
                    class="text-center header-text-style"
                    :style="header.width"
                  >
                    {{ $t(`title.${header.text}`) }}
                  </th>
                </template>

                <template v-slot:[`item`]="{ item }">
                  <tr style="text-align: center">
                    <td> <h6>{{ item.time }}</h6></td>
                    <td class="report-payout">
                      <h6>{{ moment(item.time).format("YYYYMMDD") + "#" + item.no }}</h6>
                    </td>

                    <td>
                      <div
                        v-if="passVariableModuleName == 'm_yuki'"
                        class="align-items-between "
                        :class="'c' + item.bet_type.color"
                      >
                        <h6 color="warning" class="betting-type-style-report">
                          {{ item.bet_type.name ? checkbetType(item.channel_type, item.bet_type.name ) : ''}} 
                        </h6>
                      </div>
                      <div v-else-if="passVariableModuleName == 'm_lottery'" >
                        <h6 :style="{ color: item.bet_color }" class="betting-type-style-report">
                          {{ item.bet_type }}:{{ item.betting }}
                        </h6>
                      </div>
                      <div v-else-if="passVariableModuleName == 'm_lottery3'" >
                        <h6  class="betting-type-style-report">
                          {{ item.bet_type }}:{{ item.betting }}
                        </h6>
                      </div>
                    </td>

                    <td class="report-amount">
                     <h6> {{ formatMoney(item.amount) }}</h6>
                    </td>
                    <!-- win / lose -->
                    <td>
                      <div v-if="item.is_release_result">
                        <h6
                          :class="
                            item.bet_result.is_win
                              ? 'text-primary'
                              : 'text-danger'
                          "
                        >
                          {{ formatMoney(item.amount_win) }}
                      </h6>
                      </div>
                      <div v-else>--</div>
                    </td>
                    <td>
                      <h6>
                      {{
                        item.bet_result.is_win && item.amount_win > 0
                          ? formatMoney(item.amount) + " x 90 = " + item.cast
                          : !item.bet_result.is_win && item.amount_win
                          ? formatMoney(item.cast)
                          : "???"
                      }}
                      </h6>
                    </td>

                    <td >
                      <h6 v-if="item.is_release_result" class="betting-type-style-report">
                        <div
                          v-if="passVariableModuleName == 'm_yuki'"
                          class="align-items-between"
                        >
                          <span color="warning">
                            {{ item.bet_result ? checkbetType( passGetInvoiceData.channel_type, item.bet_result.name) : "--" }}
                          </span>
                        </div>
                        <div v-else-if="passVariableModuleName == 'm_lottery'">
                          <span
                            v-if="item.bet_type == 'A'"
                            :style="{
                              color: item.bet_result.a.color,
                            }"
                          >
                            {{ item.bet_result.a.number }}
                          </span>
                          <span
                            v-if="item.bet_type == 'B'"
                            :style="{
                              color: item.bet_result.b.color,
                            }"
                          >
                            {{ item.bet_result.b.number }}
                          </span>
                          <span
                            v-if="item.bet_type == 'C'"
                            :style="{
                              color: item.bet_result.c.color,
                            }"
                          >
                            {{ item.bet_result.c.number }}
                          </span>
                          <span
                            v-if="item.bet_type == 'D'"
                            :style="{
                              color: item.bet_result.d.color,
                            }"
                          >
                            {{ item.bet_result.d.number }}
                          </span>
                          <span
                            v-if="item.bet_type == 'E'"
                            :style="{
                              color: item.bet_result.e.color,
                            }"
                          >
                            {{ item.bet_result.e.number }}
                          </span>
                        </div>
                        <div v-else-if="passVariableModuleName == 'm_lottery3'">
                          <span
                            v-if="item.bet_type == 'A'"
                            :style="{
                              color: item.bet_result.a.color,
                            }"
                          >
                            {{ item.bet_result.a.number[0] }},
                          </span>
                          <span
                            v-if="item.bet_type == 'A'"
                          >
                            {{ item.bet_result.a.number.slice(1,5).toString()  }}
                          </span>
                       
                          <span
                            v-if="item.bet_type == 'B'"
                            :style="{
                              color: item.bet_result.b.color,
                            }"
                          >
                            {{ item.bet_result.b.number[0] }},
                          </span>
                          <span
                            v-if="item.bet_type == 'B'"
    
                          >
                            {{ item.bet_result.b.number.slice(1,5).toString() }}
                          </span>
                          <span
                            v-if="item.bet_type == 'C'"
                            :style="{
                              color: item.bet_result.c.color,
                            }"
                          >
                            {{ item.bet_result.c.number[0] }},
                          </span>
                          <span
                            v-if="item.bet_type == 'C'"
                          >
                            {{ item.bet_result.c.number.slice(1,5).toString() }}
                          </span>
                          <span
                            v-if="item.bet_type == 'D'"
                            :style="{
                              color: item.bet_result.d.color,
                            }"
                          >
                            {{ item.bet_result.d.number[0] }},
                          </span>
                          <span
                            v-if="item.bet_type == 'D'"
                          >
                          {{ item.bet_result.d.number.slice(1,5).toString() }}
                          </span>
                          <span
                            v-if="item.bet_type == 'E'"
                            :style="{
                              color: item.bet_result.e.color,
                            }"
                          >
                            {{ item.bet_result.e.number[0] }},
                          </span>
                          <span
                            v-if="item.bet_type == 'E'"
                          >
                          {{ item.bet_result.e.number.slice(1,5).toString() }}
                          </span>
                        </div>
                      </h6>
                      <div v-else>---</div>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </template>
          </v-col>
          <!-- end_new -->
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
  </div>
  
  <!-- end dialog1 -->
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import gFunction from "~mixin/MX_ShareFunctionCollection.js";
import headerDetailReport from "../_api/detailReportHeader";
import BettingTypeSection from "./DataTableComponent/BettingType.vue";
import Results from "./DataTableComponent/Results.vue";

export default {
  components: {
    BettingTypeSection,
    Results,
  },
  mixins: [gFunction],
  props: [
    "passDialog",
    "passGetInvoiceData",
    "passVariableModuleName",
    "passDetailLoading",
    "passIsShow",
    "passGetInvoiceDetailData",
  ],
  data() {
    return {

      headerDetailReport: headerDetailReport,
      dataDetail: {
        rows_per_page: 200,
        page: 1,
        end_date: "",
        start_date: "",
      },
    };
  },
  computed: {
    ...mapGetters("$_allstatement", ["getLoading"]),
  },
  methods: {
    moment,
    showDeail() {
      this.$emit("showDeail");
    },
    _getResultClass(x) {
      switch (x) {
        case "Banker 1":
          return "B1";
        case "Banker 2":
          return "B2";
        case "Banker 3":
          return "B3";
        case "Player 1":
          return "P1";
        case "Player 2":
          return "P2";
        case "Player 3":
          return "P3";
        case "CANCEL":
          return "Cancel";
        case "Tie 1":
          return "T1";
        case "Tie 2":
          return "T2";
        case "Tie 3":
          return "T3";
        default:
          return "";
      }
    },
  },
};
</script>

<style>
.klaklouk-img {
  text-align: center;
  margin-bottom: 4px;
  height: 35px !important;
  width: 35px !important;
  margin: auto;
}

.show-invoice:hover {
  cursor: pointer;
  color: black;
}

.detail-invoice > div {
  overflow-y: scroll;
}
</style>